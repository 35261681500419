.heading .heading-heading h1 {
    font-size: 86px;
    line-height: 1;
    width: 80%;
    letter-spacing: -2px;
    font-weight: 700;
    strong {
        text-decoration-color: var(--color-base-bg-3);
    }
}
