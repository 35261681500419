.grid {
}
.grid-footer {
  display: flex;
  justify-content: flex-start;
  &.grid-footer-align-right {
    justify-content: flex-end;
  }
  &.grid-footer-align-center {
    justify-content: center;
  }
  .grid-view-more-button {
    padding: 6px 12px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: var(--color-primary-bg);
    font-family: $font-family-base;
  }
}
.grid-heading {
  margin-bottom: 16px;
  h2 {
    text-transform: uppercase;
    font-size: 16px;
    border-left: none;
    padding-left: 0;
    font-weight: 500;
    color: var(--color-base-text);
    font-family: $font-family-base;
  }
  p {
    font-size: 18px;
  }
}
