.page-post {
  .title {
    h1 {
      font-weight: 500;
    }
  }
  .categories {
    .category-link {
      display: inline-block;
    a {
        font-size: 15px;
        background: var(--color-base-bg-2);
        color: var(--color-base-text);
        display: inline-flex;
        font-family: $font-family-monospace;
        padding: 6px 12px;
        border-radius: $card-border-radius;
        margin-right: 10px;
        &:hover {
          text-decoration: none;
          background: var(--color-base-bg-3);
        }
      }
    }
  }
}
