:root {
  --font-family-base: 'DM Sans', sans-serif;
  --font-family-heading: 'DM Sans', sans-serif;
  --font-family-monospace: 'Fira Mono', monospace;
  --font-family-logo: 'DM Sans', sans-serif;

  --color-primary-bg: #0d042b;
  --color-primary-bg-2: #0d042b;
  --color-primary-bg-3: #0d042b;
  --color-primary-text: #fdfcf9;
  --color-base-bg: #fdfcf9;
  --color-base-bg-2: #f7f6ed;
  --color-base-bg-3: #faebd7;
  --color-base-text: #0d042b;
  --color-base-text-2: #3b364c;
  --color-header-text:  #0d042b;
  
  --logo-text-font-size: 28px;
  --logo-text-font-weight: 500;

  --menu-font-family: 'DM Sans', sans-serif;
  --menu-font-size: ;
  --menu-text-transform: "Capitalize";
  --menu-font-weight: 400;

  --header-height: 60px;
  --section-padding: 80px;
  --card-border-radius: 5px;
  --button-border-radius: 5px;
}

html[data-bs-theme='dark'] {
  --color-primary-bg: #fdfcf9;
  --color-primary-bg-2: #fdfcf9;
  --color-primary-bg-3: #fdfcf9;
  --color-primary-text: #0b0326;
  --color-base-bg: #0b0326;
  --color-base-bg-2: #150c32;
  --color-base-bg-3: #251654;
  --color-base-text: #f5f5ec;
  --color-base-text-2: #929eaf;
  --color-logo-text: #fdfcf9;
  --color-header-text: #fdfcf9;
}

@import 'style';